import React, { Component } from "react";

import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";

import { Link } from "react-router-dom";

import PaletteNameForm from "./PaletteNameForm";

import styles from "./styles/NewPaletteFormNavStyles";

class NewPaletteFormNav extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formShowing: false,
		};
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	showForm = () => {
		this.setState({
			formShowing: true,
		});
	};

	hideForm = () => {
		this.setState({
			formShowing: false,
		});
	};

	render() {
		const { classes, open, palettes, handleSubmit } = this.props;
		return (
			<div className={classes.root}>
				<CssBaseline />
				<AppBar
					position='fixed'
					color='default'
					className={classNames(classes.appBar, {
						[classes.appBarShift]: open,
					})}
				>
					<Toolbar disableGutters={!open}>
						<IconButton
							color='inherit'
							aria-label='Open drawer'
							onClick={this.props.handleDrawerOpen}
							className={classNames(classes.menuButton, open && classes.hide)}
						>
							<MenuIcon />
						</IconButton>
						<Typography variant='h6' color='inherit' noWrap>
							Create a new Palat
						</Typography>
					</Toolbar>
					<div className={classes.navButtons}>
						<Link to='/'>
							<Button
								variant='contained'
								color='secondary'
								className={classes.button}
							>
								Go Back
							</Button>
						</Link>
						<Button
							variant='contained'
							color='primary'
							className={classes.button}
							onClick={this.showForm}
						>
							Save
						</Button>
					</div>
				</AppBar>

				{this.state.formShowing && (
					<PaletteNameForm
						palettes={palettes}
						handleSubmit={handleSubmit}
						hideForm={this.hideForm}
					/>
				)}
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(NewPaletteFormNav);
