const styles = {
    root: {
        width: '100%'
    },
    picker: {
        width: '100% !important',
        marginTop: '2rem'
    },
    addColourButton: {
        width: '100%',
        padding: '1rem',
        marginTop: '1rem',
        fontSize: '2rem'
    },
    colourNameInput: {
        width: '100%',
        height: '70px',
    }
}

export default styles;