import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/styles';
import DeleteIcon from "@material-ui/icons/Delete"

import styles from './styles/MiniPaletteStyles';

class MiniPalette extends PureComponent {

    deletePalat = (event) => {
        event.stopPropagation();
        this.props.openDialog(this.props.id);
    }
    handleClick = () => {
        this.props.goToPalette(this.props.id);
    }

    render() {
        const { classes, paletteName, emoji, colors, } = this.props;
        const miniColourBoxes = colors.map(color => (
            <div 
                className={classes.miniColour} 
                style={{backgroundColor: color.color}}
                key={color.name}
            />
        ))

    return (
        <div className={classes.root} onClick={this.handleClick}>

                <DeleteIcon className={classes.deleteIcon} onClick={this.deletePalat} />

            <div className={classes.colours}>
                {miniColourBoxes}
            </div>
            <h5 className={classes.title}>
                {paletteName}
                <span className={classes.emoji}>
                    {emoji}
                </span>
            </h5>
        </div>
    )
    }
    
}

export default withStyles(styles)(MiniPalette);