import React, { Component } from "react";

import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Button from "@material-ui/core/Button";

import DraggableColourList from "./DraggableColourList";
import { arrayMove } from "react-sortable-hoc";

import NewPaletteFormNav from "./NewPaletteFormNav";
import ColourPickerForm from "./ColourPickerForm";

import styles from "./styles/NewPaletteFormStyles";
import seedColours from "./seedColours";

class NewPaletteForm extends Component {
	static defaultProps = {
		maxColours: 20,
	};
	constructor(props) {
		super(props);
		this.state = {
			open: true,
			colours: seedColours[1].colors,
			newPaletteName: "",
		};
	}

	handleDrawerOpen = () => {
		this.setState({ open: true });
	};

	handleDrawerClose = () => {
		this.setState({ open: false });
	};

	addColour = (newColour) => {
		this.setState({
			colours: [...this.state.colours, newColour],
			newColourName: "",
		});
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	clearColours = () => {
		this.setState({
			colours: [],
		});
	};

	randomColour = () => {
		// the below maps all colours from all arrays into arrays of just colours
		// flat() flattens these arrays into one singular array
		const allColours = this.props.palettes
			.map((palette) => palette.colors)
			.flat();
		let random;
		let chosenOne = allColours[random];
		let isDuplicateColour = true;
		while (isDuplicateColour) {
			random = Math.floor(Math.random() * allColours.length);
			chosenOne = allColours[random];
			isDuplicateColour = this.checkForDuplicate(chosenOne.name);
		}
		this.setState({
			colours: [...this.state.colours, chosenOne],
		});
	};

	checkForDuplicate(colourName) {
		this.state.colours.some((colour) => colour.name === colourName);
	}

	handleSubmit = (submittedPalette) => {
		const newPalette = {
			paletteName: submittedPalette.name,
			id: submittedPalette.name.toLowerCase().replace(/ /g, "-"),
			emoji: submittedPalette.emoji,
			colors: this.state.colours,
		};
		this.props.savePalette(newPalette);
		// this.props.sendPalette(newPalette);
		this.props.history.push("/");
	};

	removeColour = (colourName) => {
		this.setState({
			colours: this.state.colours.filter((color) => color.name !== colourName),
		});
	};

	onSortEnd = ({ oldIndex, newIndex }) => {
		this.setState(({ colours }) => ({
			colours: arrayMove(colours, oldIndex, newIndex),
		}));
	};

	render() {
		const { classes, maxColours, palettes } = this.props;
		const { open, colours } = this.state;
		const paletteFull = colours.length >= maxColours;

		return (
			<div className={classes.root}>
				<NewPaletteFormNav
					open={open}
					palettes={palettes}
					handleSubmit={this.handleSubmit}
					handleDrawerOpen={this.handleDrawerOpen}
				/>

				<Drawer
					className={classes.drawer}
					variant='persistent'
					anchor='left'
					open={open}
					classes={{
						paper: classes.drawerPaper,
					}}
				>
					<div className={classes.drawerHeader}>
						<IconButton onClick={this.handleDrawerClose}>
							<ChevronLeftIcon />
						</IconButton>
					</div>
					<Divider />
					<div className={classes.drawerContainer}>
						<Typography variant='h4' gutterBottom>
							Create Your Palat.
						</Typography>
						<div className={classes.buttons}>
							<Button
								variant='contained'
								color='secondary'
								className={classes.button}
								onClick={this.clearColours}
							>
								Clear Palette
							</Button>
							<Button
								variant='contained'
								color='primary'
								className={classes.button}
								onClick={this.randomColour}
								disabled={paletteFull}
							>
								Random Colour
							</Button>
						</div>
						<ColourPickerForm
							paletteFull={paletteFull}
							addColour={this.addColour}
							colours={this.state.colours}
						/>
					</div>
				</Drawer>
				<main
					className={classNames(classes.content, {
						[classes.contentShift]: open,
					})}
				>
					<div className={classes.drawerHeader} />
					<DraggableColourList
						colours={this.state.colours}
						removeColour={this.removeColour}
						axis='xy'
						onSortEnd={this.onSortEnd}
						distance={15}
					/>
				</main>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(NewPaletteForm);
