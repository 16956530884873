import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';

import styles from './styles/ColourBoxStyles';

class ColourBox extends Component {
    constructor(props) {
        super(props);
        this.state = { copied: false };
        this.changeCopyState = this.changeCopyState.bind(this);
    }
    changeCopyState(){
        this.setState({copied: true}, () => {
            setTimeout(() => this.setState({copied: false}), 1500)
        });
    }
    render() {
        const { name, colour, moreUrl, isFullPalette, classes } = this.props;
        const { copied } = this.state;

        return (
            <CopyToClipboard text={colour} onCopy={this.changeCopyState}>
                <div style={{ background: colour }} className={classes.ColourBox}>
                    <div 
                        style={{ background: colour }} 
                        className={classNames(classes.copyOverlay, {[classes.showOverlay]: copied})} 
                    />
                    <div className={classNames(classes.copyMessage, {[classes.showMessage]: copied})}>
                        <h1>Copied!</h1>
                        <p className={classes.copyText}>{this.props.colour}</p>
                    </div>
                    <div className='copy-container'>
                        <div className={classes.boxContent}>
                            <span className={classes.colourName}>{name}</span>
                        </div>
                        <button className={classes.copyButton}>Copy</button>
                    </div>
                    {isFullPalette && (
                        <Link to={moreUrl} onClick={event => event.stopPropagation()}>
                        <span className={classes.seeMore}>MORE</span>
                        </Link>
                    )}                 
                </div>
            </CopyToClipboard>
            
        )
    }
}

export default withStyles(styles)(ColourBox);