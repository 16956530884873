import { Component } from "react";
import { Route, Switch } from "react-router-dom";

import axios from "axios";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import Page from "./Page";
import Palette from "./Palette";
import seedPalette from "./seedColours";
import PaletteList from "./PaletteList";
import SingleColourPalette from "./SingleColourPalette";
import NewPaletteForm from "./NewPaletteForm";

import { generatePalette } from "./colorHelpers";

class App extends Component {
	constructor(props) {
		super(props);
		const localPalettes = JSON.parse(window.localStorage.getItem("palettes"));
		this.state = { palettes: localPalettes || seedPalette };
	}

	componentDidMount() {
		this.requestPalettes();
	}

	findPalette = (id) => {
		return this.state.palettes.find(function (palette) {
			return palette.id === id;
		});
	};
	savePalette = (newPalette) => {
		this.setState(
			{ palettes: [...this.state.palettes, newPalette] },
			this.sendPalette(newPalette)
		);
	};
	deletePalette = (id) => {
		this.setState(
			(oldState) => ({
				palettes: oldState.palettes.filter(
					// for each palette, allow everything through that is not equal to the given ID
					(palette) => palette.id !== id
				),
			}),
			this.syncLocalStorage
		);
	};
	syncLocalStorage() {
		window.localStorage.setItem(
			"palettes",
			JSON.stringify(this.state.palettes)
		);
	}

	sendPalette(newPalette) {
		// console.log(newPalette);
		axios.post(`https://api.studiochalmers.com/palettes/add`, newPalette).then(
			(res) => {
				console.log(res);
				console.log(res.data);
			},
			(error) => {
				console.log(error);
			}
		);
	}
	removePalette = (id) => {
		axios.delete(`https://api.studiochalmers.com/palettes/${id}`).then(
			(res) => {
				console.log(res);
				console.log(res.data);
				this.requestPalettes();
			},
			(error) => {
				console.log(error);
			}
		);
	};

	requestPalettes = () => {
		axios.get(`https://api.studiochalmers.com/palettes/`).then((res) => {
			const paletteData = res.data;
			console.log("palette data from API: ");
			console.log(paletteData);
			this.setState({ palettes: paletteData });
		});
	};

	render() {
		return (
			<Route
				render={({ location }) => (
					<TransitionGroup>
						<CSSTransition classNames='page' key={location.key} timeout={500}>
							<Switch location={location}>
								{/* Home - list of palettes */}
								<Route
									exact
									path='/'
									render={(routeProps) => (
										<Page>
											<PaletteList
												palettes={this.state.palettes}
												deletePalette={this.deletePalette}
												removePalette={this.removePalette}
												{...routeProps}
											/>
										</Page>
									)}
								/>
								{/* New Palette */}
								<Route
									exact
									path='/palette/new'
									render={(routeProps) => (
										<Page>
											<NewPaletteForm
												savePalette={this.savePalette}
												sendPalette={this.sendPalette}
												palettes={this.state.palettes}
												{...routeProps}
											/>
										</Page>
									)}
								/>
								{/* Palette Page */}
								<Route
									exact
									path='/palette/:id'
									render={(routeProps) => (
										<Page>
											<Palette
												palette={generatePalette(
													this.findPalette(routeProps.match.params.id)
												)}
											/>
										</Page>
									)}
								/>
								{/* Single Colour Page */}
								<Route
									exact
									path='/palette/:paletteId/:colourId'
									render={(routeProps) => (
										<Page>
											<SingleColourPalette
												palette={generatePalette(
													this.findPalette(routeProps.match.params.paletteId)
												)}
												colourId={routeProps.match.params.colourId}
											/>
										</Page>
									)}
								/>
								{/* Any other URL */}
								<Route
									render={(routeProps) => (
										<Page>
											<PaletteList
												palettes={this.state.palettes}
												deletePalette={this.deletePalette}
												{...routeProps}
											/>
										</Page>
									)}
								/>
							</Switch>
						</CSSTransition>
					</TransitionGroup>
				)}
			/>
		);
	}
}

export default App;
