/* eslint-disable import/no-anonymous-default-export */

import sizes from './sizes';

export default {
    Navbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "6vh",
    },
    logo: {
        marginRight: "15px",
        padding: "0 13px",
        fontSize: "22px",
        backgroundColor: "thistle",
        fontFamily: "Roboto",
        height: "100%",
        display: "flex",
        alignItems: "center",
        "& a": {
            textDecoration: "none",
            color: "black",
        },
        [sizes.down('xs')]: {
            display: 'none'
        },
    },
    slider: {
        width: "350px",
        margin: "0 10px",
        display: "inline-block",
        "& .rc-slider-rail": {
            height: "8px",
        },
        "& .rc-slider-handle:active, .rc-slider-handle:hover, .rc-slider-handle:focus, .rc-slider-handle": {
            backgroundColor: "violet",
            outline: "none",
            border: "2px solid violet",
            boxShadow: "none",
        },
        "& .rc-slider-track": {
            backgroundColor: "transparent",
        },
        [sizes.down('md')]: {
            width: '150px'
        },
    },
    selectContainer: {
        marginLeft: "auto",
        marginRight: "1rem",
    }
}