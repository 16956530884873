import React from 'react';

import { SortableContainer } from 'react-sortable-hoc';

import DraggableColourBox from './DraggableColourBox';

function DraggableColourList(props) {
    const { colours, removeColour } = props;
    return (
        <div style={{height: "100%"}}>
            {colours.map((colour, i) => (
                <DraggableColourBox 
                    index={i}
                    colour={colour.color}
                    key={colour.name}
                    name={colour.name}
                    handleClick={() => removeColour(colour.name)}
                />
            ))}
        </div>
    )
}

export default SortableContainer(DraggableColourList);