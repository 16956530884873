// eslint-disable-next-line import/no-anonymous-default-export
export default {
    root: {
        backgroundColor: "white",
        border: "1px solid black",
        borderRadius: "5px",
        padding: "0.5rem",
        position: "relative",
        overflow: "hidden",
        cursor: "pointer",
        "&:hover svg": {
            opacity: 1
        }
    },
    colours: {
        backgroundColor: "#dae1e4",
        height: "150px",
        width: "100%",
        borderRadius: "5px",
        overflow: "hidden"
    },
    title: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "0",
        color: "black",
        paddingTop: "0.5rem",
        fontSize: "1rem",
        position: "relative"
    },
    emoji: {
        marginLeft: "0.5rem",
        fontSize: "1.5rem"
    },
    miniColour: {
        height: "25%",
        width: "20%",
        display: "inline-block",
        margin: "0 auto",
        position: "relative",
        marginBottom: "-5px"
    },
    deleteIcon: {
        color: 'white',
        backgroundColor: '#eb3d30',
        height: '20px',
        width: '20px',
        position: 'absolute',
        right: 0,
        top: 0,
        padding: '10px',
        zIndex: '3',
        opacity: '0',
        transition: 'all 0.2s ease-in-out',
        boxSizing: "initial" //fix issue
    }
}