import React from 'react';
import { withStyles } from '@material-ui/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { SortableElement } from "react-sortable-hoc";

import styles from './styles/DraggableColourBoxStyles';

const DraggableColourBox = SortableElement((props) => {
    const { classes, colour, name, handleClick } = props;
    return (
        <div className={classes.root} style={{backgroundColor: colour}}>
            <div className={classes.boxContent}>
                <span>{name}</span>
                <DeleteForeverIcon 
                    className={classes.deleteIcon} 
                    onClick={handleClick}
                />
            </div>
        </div>
    )
})

export default withStyles(styles)(DraggableColourBox);