import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart';

class PaletteNameForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stage: 'name',
            newPaletteName: ''
        }
    }

    componentDidMount() {

        ValidatorForm.addValidationRule('isPaletteNameUnique', (value) => 
            this.props.palettes.every(
                ({paletteName}) => paletteName.toLowerCase() !== value.toLowerCase()
            )
        );
    }

    handleChange = (event) => {
        this.setState({
            [ event.target.name]: event.target.value
        })
    }

    emojiStage = () => {
        this.setState({
            stage: 'emoji'
        })
    }

    submitPalette = (selectedEmoji) => {
        console.log(selectedEmoji.native);
        const palette = {name: this.state.newPaletteName, emoji: selectedEmoji.native};
        this.props.handleSubmit(palette);
        this.setState({
            stage: ''
        })
    }

    render() {
        const { newPaletteName, stage } = this.state;
        const { hideForm } = this.props;
        return (
            <div>
                <Dialog open={stage === 'emoji'} onClose={hideForm} aria-labelledby="emoji-dialog-title">
                    <DialogTitle id="emoji-dialog-title">Pick an emoji for this Palat</DialogTitle>
                    <Picker title='Pick an emoji for this Palat' onSelect={this.submitPalette} />
                </Dialog>

                <Dialog open={stage === 'name'} onClose={hideForm} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Choose a Name For Your Palat</DialogTitle>
                    <ValidatorForm onSubmit={this.emojiStage}>
                    <DialogContent>
                        <DialogContentText>
                            Enter a Unique Name for your Palat
                        </DialogContentText >
                        
                        <TextValidator 
                            label="Palette Name" 
                            value={newPaletteName} 
                            name="newPaletteName" 
                            onChange={this.handleChange}
                            fullWidth
                            margin='normal'
                            validators={["required", "isPaletteNameUnique"]}
                            errorMessages={["give it a name", "this name is already in use"]}
                        />
                            
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={hideForm} color="primary">
                            Cancel
                        </Button>
                        <Button variant='contained' color='primary' type='submit'>Save Palette</Button>
                    </DialogActions>
                    </ValidatorForm>
                </Dialog>
            </div>
            
        );
    }
}

export default PaletteNameForm;