import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';

import ColourBox from './ColourBox';
import Navbar from './Navbar';
import PaletteFooter from './PaletteFooter';

import styles from './styles/PaletteStyles';

class Palette extends Component {
    constructor(props) {
        super(props);
        this.state = {
            level: 500,
            format: 'hex'
        }
        this.changeLevel = this.changeLevel.bind(this);
        this.changeFormat = this.changeFormat.bind(this);
    }
    changeLevel(level) {
        // you can just one item if name and value are the same as here:-
        this.setState({ level });
    }
    changeFormat(format) {
        this.setState({ format })
    }
    render()  {
        const { colors, paletteName, emoji, id } = this.props.palette;
        const { classes } = this.props;
        const { level, format } = this.state;
        const colourBoxes = colors[level].map(col => (
            <ColourBox 
                colour={col[format]} 
                name={col.name} 
                key={col.id}
                moreUrl={`/palette/${id}/${col.id}`}
                isFullPalette
            />
        ));
        return(
            <div className={classes.Palette}>
                <Navbar 
                    level={ level } 
                    changeLevel={this.changeLevel} 
                    handleChange={this.changeFormat} 
                    showSlider
                />
                <div className={classes.PaletteColours}>
                    {colourBoxes}
                </div>
                <PaletteFooter paletteName={paletteName} emoji={emoji} />
            </div>
        )
    }
}

export default withStyles(styles)(Palette);