import React, { Component } from 'react';

import Button from '@material-ui/core/Button'

import { ChromePicker } from 'react-color';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles/ColourPickerFormStyles';

class ColourPickerForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            currentColour: 'pink',
            newColourName: ''
        }
    }

    updateCurrentColour = (newColour) => {
        this.setState({
            currentColour: newColour.hex,
            newColourName: '',
        })
    }

    handleChange = (event) => {
        this.setState({
            [ event.target.name]: event.target.value
        })
    }

    handleSubmit = () => {
        const newColour = {
            color: this.state.currentColour,
            name: this.state.newColourName
        }
        this.props.addColour(newColour);
        this.setState({
            newColourName: ''
        })
    }

    componentDidMount() {

        ValidatorForm.addValidationRule('isColourNameUnique', (value) => 
            this.props.colours.every(
                ({name}) => name.toLowerCase() !== value.toLowerCase()
            )
        );

        ValidatorForm.addValidationRule('isColourUnique', (value) => 
            this.props.colours.every(
                ({colour}) => colour !== this.state.currentColour
            )
        );
    }

    render() {
        const { paletteFull, classes } = this.props;
        return (
            <div className={classes.root}>
                <ChromePicker 
                    color={this.state.currentColour}
                    onChangeComplete={this.updateCurrentColour}
                    className={classes.picker}
                />
                <ValidatorForm onSubmit={this.handleSubmit}>
                    <TextValidator 
                        value={this.state.newColourName}
                        className={classes.colourNameInput}
                        placeholder="Colour Name"
                        onChange={this.handleChange}
                        name='newColourName'
                        variant='filled'
                        margin='normal'
                        validators={['required', 'isColourNameUnique', 'isColourUnique']}
                        errorMessages={['give it a name', 'this name is already in use', 'you already added this colour']}
                    />
                    <Button 
                        type='submit'
                        variant='contained'
                        color='primary'
                        style={{ backgroundColor: paletteFull ? 'grey' : this.state.currentColour }}
                        disabled={paletteFull}
                        className={classes.addColourButton}
                    >
                        {paletteFull ? "Palette Full" : "Add Colour"}
                    </Button>
                </ValidatorForm>
            </div>
        )
    }
}

export default withStyles(styles)(ColourPickerForm);