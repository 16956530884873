// eslint-disable-next-line import/no-anonymous-default-export
export default {
    PaletteFooter: {
        backgroundColor: "white",
        height: "5vh",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        fontWeight: "bold",
        overflow: "hidden",
    },
    emoji: {
        fontSize: "1.5rem",
        margin: "0 1rem",
    }
}