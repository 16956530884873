import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';

import Navbar from './Navbar';
import ColourBox from './ColourBox';
import PaletteFooter from './PaletteFooter';

import styles from './styles/PaletteStyles';

class SingleColourPalette extends Component {
    constructor(props) {
        super(props);
        this.state = {
            format: "hex"
        }
        this._shades = this.getAllShades(this.props.palette, this.props.colourId);
        this.changeFormat = this.changeFormat.bind(this);
    }
    getAllShades(palette, colourToFilterBy) {
        // return all shades of given colour
        let shades = [];
        let allColours = palette.colors;
        for(let key in allColours) {
            shades = shades.concat(
                allColours[key].filter(colour => colour.id === colourToFilterBy)
            )
        }
        return shades.slice(1);
    }
    changeFormat(format) {
        this.setState({ format })
    }
    render() {
        const { format } = this.state;
        const { paletteName, emoji, id } = this.props.palette;
        const { classes } = this.props;
        const colourBoxes = this._shades.map(colour => (
            <ColourBox 
                key={colour.name}
                name={colour.name}
                colour={colour[format]}
                isFullPalette={false}
                showSlider={false}
            />
        ));
        return (
            <div className={classes.Palette}>
                <Navbar 
                    handleChange={this.changeFormat}
                />
                <div className={classes.PaletteColours}>
                    { colourBoxes }
                    <div className={classes.goBack}>
                        <Link to={`/palette/${id}`}>GO BACK</Link>
                    </div>
                </div>
                <PaletteFooter paletteName={paletteName} emoji={emoji} />
            </div>
        )
    }
}

export default withStyles(styles)(SingleColourPalette);